import React from 'react'
import ArtsCarousel from '../carousel/artCarousel'
import Quotes from '../categories/Quotes'
import NewseLatter2 from '../dao/newseLatter2'
import HeroSearch from '../hero/hero_search'
import FeaturesProfiles from '../categories/FeaturesProfiles'
import Loader from '../preloader/Loader'
import { WithLoading } from '../HOC/WithLoading'

const HomeMain = ({ celebrities, featuredCelebrities, quotes, isLoading }) => {
  
  const ContentWithLoading = WithLoading(isLoading, {
    componentWhenLoadingStart: <div className='h-[80vh] flex items-center justify-center'>
      <Loader />
    </div>,
    componentWhenLoadingEnd: <>
      <div className='container relative'>
        <ArtsCarousel data={celebrities} />
      </div>
      <FeaturesProfiles
        featuredCelebrities={featuredCelebrities.celebrities}
        pagination={featuredCelebrities.pagination}
      />
      <Quotes quotes={quotes} />
    </>,
  })
  
  return (
    <main>
      <HeroSearch />

      <ContentWithLoading />
      
      <NewseLatter2 bgWhite={false} />
    </main>
  )
}

export default HomeMain
